<template>
  <FullPopup cols="col-11 col-md-8 col-lg-7 col-xxl-6">
    <ContentWithBackgroundAndFooter>
      <div class="row my-3 normal-color">
        <div class="col-12 text-end cursor d-none d-md-block lh-1" @click="$emit('close')">
          <ios-close-icon class="inline-icon big" />
        </div>
        <div class="col-12">
          <div class="row mx-0 justify-content-center">
            <div class="col-12 p-0">
              <div class="row m-0 justify-content-center">
                <div class="col-auto text-center px-0 pb-3 border_bottom mb-3">
                  <div class="card_title">
                    RECURRING PAYMENT
                  </div>
                </div>
              </div>
            </div>
            <WizardSteps :wizardSteps="wizardSteps" :activeComponent="activeComponent" v-if="showWizardSteps" />
          </div>
        </div>
        <LoginSection v-if="!user" />
        <SetupNewSection
          v-else-if="activeComponent === 'SetupNew'"
          v-model:selectedProject="selectedProject"
          v-model:donation="donation"
          v-model:donationType="donationType"
          :defaultCurrency="defaultCurrency"
        />
        <FrequencySection
          v-else-if="activeComponent === 'Frequency'"
          v-model:frequency="frequency"
          :total="total"
        />
        <PreferencesSection
          v-else-if="activeComponent === 'Preferences'"
          v-model:startDate="startDate"
          v-model:endDate="endDate"
          v-model:untilCancelled="untilCancelled"
          :frequency="frequency"
        />
        <PaymentSection
          v-else-if="activeComponent === 'Payment'"
          v-model:isLoading="isLoading"
          :checkCard="checkCard"
          :total="total"
          :donationSaved="donationSaved"
          @stripeContinue="processDonation"
        />
        <ItemsInCartSection v-else-if="cart && cart.length > 0" />
      </div>
    </ContentWithBackgroundAndFooter>
    <Footer>
      <LoginFooter v-if="!user" />
      <SetupNewFooter
        v-else-if="activeComponent === 'SetupNew'"
        v-model:activeComponent="activeComponent"
        :theSteps="theSteps"
        :donation="donation"
        :donationType="donationType"
        :selectedProject="selectedProject"
        @addDonationToCart="addDonationToCart"
        @close="$emit('close')"
      />
      <FrequencyFooter
        v-else-if="activeComponent === 'Frequency'"
        v-model:activeComponent="activeComponent"
        :theSteps="theSteps"
        :frequency="frequency"
      />
      <PreferencesFooter
        v-else-if="activeComponent === 'Preferences'"
        v-model:activeComponent="activeComponent"
        :theSteps="theSteps"
        :hasCardDetails="hasCardDetails"
        :startDate="startDate"
        :isLoading="isLoading"
        @checkCard="verifyCard"
      />
      <PaymentFooter
        v-else-if="activeComponent === 'Payment'"
        v-model:activeComponent="activeComponent"
        :theSteps="theSteps"
        :isLoading="isLoading"
        @checkCard="verifyCard"
      />
      <ItemsInCartFooter
        v-else-if="cart && cart.length > 0"
        v-model:activeComponent="activeComponent"
        v-model:usingCart="usingCart"
        @continueCart="useCart"
      />
    </Footer>
  </FullPopup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    FullPopup: defineAsyncComponent(() => import('@/components/styling/FullPopup.vue')),
    ContentWithBackgroundAndFooter: defineAsyncComponent(() => import('@/components/styling/ContentWithBackgroundAndFooter.vue')),
    WizardSteps: defineAsyncComponent(() => import('./views/WizardSteps.vue')),
    Footer: defineAsyncComponent(() => import('./footer/Footer.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    LoginSection: defineAsyncComponent(() => import('./steps/LoginSection.vue')),
    LoginFooter: defineAsyncComponent(() => import('./footer/views/LoginFooter.vue')),
    ItemsInCartSection: defineAsyncComponent(() => import('./steps/ItemsInCartSection.vue')),
    ItemsInCartFooter: defineAsyncComponent(() => import('./footer/views/ItemsInCartFooter.vue')),
    FrequencySection: defineAsyncComponent(() => import('./steps/FrequencySection.vue')),
    FrequencyFooter: defineAsyncComponent(() => import('./footer/views/FrequencyFooter.vue')),
    SetupNewSection: defineAsyncComponent(() => import('./steps/SetupNewSection.vue')),
    SetupNewFooter: defineAsyncComponent(() => import('./footer/views/SetupNewFooter.vue')),
    PreferencesSection: defineAsyncComponent(() => import('./steps/PreferencesSection.vue')),
    PreferencesFooter: defineAsyncComponent(() => import('./footer/views/PreferencesFooter.vue')),
    PaymentSection: defineAsyncComponent(() => import('./steps/PaymentSection.vue')),
    PaymentFooter: defineAsyncComponent(() => import('./footer/views/PaymentFooter.vue'))
  },
  name: 'Recurring Donations UK',
  props: ['isRecurringReady', 'hasCardDetails'],
  emits: ['checkRecurringCheckout', 'checkoutRecurring'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      activeComponent: null,
      usingCart: false,
      isLoading: false,
      checkCard: false,
      frequency: 'MONTHLY',
      startDate: new Date().toISOString().split('T')[0],
      endDate: '',
      untilCancelled: true,
      selectedProject: {},
      donation: {
        amount: null,
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      },
      donationType: '',
      currentDonation: [],
      donationDtoList: [],
      donationSaved: null
    }
  },
  async beforeMount () {
    this.donation.currency = this.defaultCurrency
    if (this.hasCardDetails) {
      await this.fromCheckout()
    } else {
      await this.fromNonCheckout()
    }
  },
  watch: {
    async isRecurringReady () {
      if (this.isRecurringReady) {
        await this.processDonation()
      }
    }
  },
  computed: {
    ...mapGetters([
      'cart', 'user'
    ]),
    theSteps () {
      let steps = []
      if (!this.user) {
        steps = ['Login']
      } else {
        if (this.cart && this.cart.length > 0) {
          if (this.usingCart) {
            if (this.hasCardDetails) {
              steps = ['ItemsInCart', 'Frequency', 'Preferences']
            } else {
              steps = ['ItemsInCart', 'Frequency', 'Preferences', 'Payment']
            }
          } else {
            steps = ['ItemsInCart', 'SetupNew', 'Frequency', 'Preferences', 'Payment']
          }
        } else {
          steps = ['SetupNew', 'Frequency', 'Preferences', 'Payment']
        }
      }
      return steps
    },
    wizardSteps () {
      if (this.hasCardDetails) {
        return [
          {
            stepNumber: 1,
            title: 'Frequency'
          },
          {
            stepNumber: 2,
            title: 'Preferences'
          }
        ]
      } else {
        return [
          {
            stepNumber: 1,
            title: 'Frequency'
          },
          {
            stepNumber: 2,
            title: 'Preferences'
          },
          {
            stepNumber: 3,
            title: 'Payment'
          }
        ]
      }
    },
    showWizardSteps () {
      const index = this.wizardSteps.findIndex(x => x.title === this.activeComponent)
      if (index > -1) {
        return true
      } else {
        return false
      }
    },
    total () {
      if (this.currentDonation.length > 0) {
        let ret = 0
        this.currentDonation.forEach(item => {
          ret += Number(item.amount)
        })
        return this.currentDonation[0].currency + ' ' + parseFloat(Math.round(ret * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return 0
      }
    }
  },
  methods: {
    ...mapActions(['saveRecurringDonation']),
    useCart () {
      this.currentDonation = this.cart
    },
    async fromCheckout () {
      this.usingCart = true
      this.activeComponent = 'Frequency'
      await this.useCart()
    },
    async fromNonCheckout () {
      if (this.cart && this.cart.length > 0) {
        this.activeComponent = null
      } else {
        this.activeComponent = 'SetupNew'
      }
    },
    addDonationToCart () {
      this.selectedProject.projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType !== this.donationType) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: this.donationType, amount: this.donation.amount })
        }
      })
      this.currentDonation.push(this.donation)
      this.donation = {
        amount: null,
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      }
      this.selectedProject = {}
    },
    verifyCard () {
      // HERE
      if (this.hasCardDetails) {
        this.$emit('checkRecurringCheckout')
      } else {
        this.checkCard = true
      }
    },
    addDonation () {
      let currencyID = 1
      if (this.currentDonation[0].currency !== 'R') {
        currencyID = 3
      }
      this.currentDonation.forEach(item => {
        let obj = {}
        item.donationTypes.forEach(type => {
          if (type.amount && Number(type.amount) > 0) {
            const donationTypeID = type.type ? type.type.donationType.donationTypeID : null
            obj = {
              amount: Number(type.amount),
              donationTypeID: donationTypeID,
              projectID: item.projectId ? item.projectId : null,
              currencyId: currencyID
            }
            this.donationDtoList.push(obj)
          }
        })
      })
    },
    async processDonation () {
      this.isLoading = true

      await this.addDonation()
      const saveDetails = {
        startDate: new Date(this.startDate).toISOString(),
        frequency: this.frequency,
        debitOrderDay: new Date(this.startDate).getDate(),
        dailySadaqaDonation: false,
        recurringDonationType: 'CREDIT_CARD',
        untilCancelled: this.untilCancelled,
        transactionDTO: {
          donationDtoList: this.donationDtoList,
          userID: this.user.userID ? this.user.userID : 0,
          giftAid: this.user.giftAid ? this.user.giftAid : false,
          organisationID: 0,
          sponsored: true
        }
      }
      if (!this.untilCancelled) {
        saveDetails.endDate = new Date(this.endDate).toISOString()
      }
      try {
        const ret = await this.saveRecurringDonation(saveDetails)
        if (ret && ret.status === 200) {
          if (this.hasCardDetails) {
            this.$emit('checkoutRecurring', ret)
          } else {
            this.donationSaved = ret
          }
        }
      } catch (error) {
        console.error('ERRR', error)
      }
    }
  }
}

</script>

<style scoped>
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
</style>
